import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import WhatWeDeliver from "../components/marketing/whatWeDeliver"
import CtaTwoColumn from "../components/marketing/ctaTwoColumn"
import Footer from "../components/footer"

import './index.css';

const IndexPage = () => (
  <Layout>
    <SEO title="Cloud Infrastructure Consultants" />
    <div className="relative bg-gray-50 overflow-hidden ltn-hero-bg">
      <div className="relative pt-6 pb-12 sm:pb-16 md:pb-20 lg:pb-28 xl:pb-32">
        <main className="mt-10 mx-auto max-w-screen-xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 xl:mt-28">
          <div className="text-center">
            <h2
              className="text-4xl tracking-tight leading-10 font-extrabold text-gray-900 sm:text-5xl sm:leading-none ">
              Cloud Infrastructure
              <br className="xl:hidden" />
                <span className="text-blue-600"> Specialists</span>
            </h2>
            <p className="mt-3 max-w-md mx-auto text-base text-gray-700 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
              We work with enterprises who want to use the cloud to move fast, safely.
            </p>
            <div className="mt-5 max-w-md mx-auto sm:flex sm:justify-center md:mt-8">
              <div className="rounded-md shadow">
                <Link to={"/contact"}
                   className="w-full flex items-center justify-center px-5 py-2 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-blue-600 hover:bg-blue-500 focus:outline-none focus:border-blue-700 focus:shadow-outline-blue transition duration-150 ease-in-out md:py-3 md:text-lg md:px-6">
                  Let's Talk
                </Link>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
    <WhatWeDeliver />
    <CtaTwoColumn />
    <Footer />
  </Layout>
)

export default IndexPage
