import React from 'react';
import { Link } from "gatsby";

const WhatWeDeliver = () => {
  return (
    <div className="py-12 bg-white">
      <div className="max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mt-4 mb-10">
          <p className="text-base leading-6 text-gray-600 font-semibold tracking-wide uppercase">Our Specialties</p>
          <h3 className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-700 sm:text-4xl sm:leading-10">
            Solutions We Deliver
          </h3>
          <p className="mt-4 max-w-2xl text-xl leading-7 text-gray-700 lg:mx-auto">
            {/*Projects we */}
          </p>
        </div>

        <div className="max-w-xl mx-auto px-4 sm:px-6 lg:max-w-screen-xl lg:px-8">
          <div className="lg:grid lg:grid-cols-3 lg:gap-8">
            <div className="mt-10 lg:mt-0">
              <div className="flex items-center justify-center h-10 w-10 mx-auto text-orange-500">{/**/}
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 20l4-16m4 4l4 4-4 4M6 16l-4-4 4-4"/>
                </svg>
              </div>
              <div className="mt-5 text-center">
                <h5 className="text-lg leading-6 font-semibold text-gray-900">Developer Productivity</h5>
                <p className="mt-2 text-base leading-6 text-gray-600">
                  We design systems that can unleash the full power of your developers. Let them start new
                  projects and ship code faster, while complying with enterprise standards.
                </p>
              </div>
            </div>
            <div className="mt-10 lg:mt-0">
              <div className="flex items-center justify-center h-10 w-10 mx-auto text-orange-500">{/**/}
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 6l3 1m0 0l-3 9a5.002 5.002 0 006.001 0M6 7l3 9M6 7l6-2m6 2l3-1m-3 1l-3 9a5.002 5.002 0 006.001 0M18 7l3 9m-3-9l-6-2m0-2v2m0 16V5m0 16H9m3 0h3"/>
                </svg>
              </div>
              <div className="mt-5 text-center">
                <h5 className="text-lg leading-6 font-semibold text-gray-900">Governance & Cost Control</h5>
                <p className="mt-2 text-base leading-6 text-gray-600">
                  Many organizations have dozens of cloud accounts that need to be accounted for. We can
                  design a pragmatic governance model and cost control strategy for your company.
                </p>
              </div>
            </div>
            <div className="mt-10 lg:mt-0">
              <div className="flex items-center justify-center h-10 w-10 mx-auto text-orange-500">{/**/}
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19.428 15.428a2 2 0 00-1.022-.547l-2.387-.477a6 6 0 00-3.86.517l-.318.158a6 6 0 01-3.86.517L6.05 15.21a2 2 0 00-1.806.547M8 4h8l-1 1v5.172a2 2 0 00.586 1.414l5 5c1.26 1.26.367 3.414-1.415 3.414H4.828c-1.782 0-2.674-2.154-1.414-3.414l5-5A2 2 0 009 10.172V5L8 4z"/>
                </svg>
              </div>
              <div className="mt-5 text-center">
                <h5 className="text-lg leading-6 font-semibold text-gray-900">Bold Initiatives</h5>
                <p className="mt-2 text-base leading-6 text-gray-600">
                  Adopting technologies like Terraform and Kubernetes can greatly improve cloud operations. We
                  can work with you to develop and execute a strategic IT roadmap that’ll give your company an
                  edge.
                </p>
              </div>
            </div>
          </div>
          <div className="mt-5 max-w-md mx-auto sm:flex sm:justify-center md:mt-8">
            <div className="">
              <Link to={"/services"}
                 className="w-full flex items-center justify-center px-5 py-2 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-blue-600 hover:bg-blue-500 focus:outline-none focus:border-blue-700 focus:shadow-outline-blue transition duration-150 ease-in-out md:py-3 md:text-lg md:px-6">
                View our Services
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default WhatWeDeliver;
